/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.UserNameAndIcon {
  padding-left: 16px;
  padding-right: 16px;
}
.UserNameAndIcon__icon {
  margin-right: 16px;
}
